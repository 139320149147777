import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"
import Main from "../components/main"
import FilterCatsRadio from "../components/filtercatsradio"
import FilterCatsDropdown from "../components/filtercatsdropdown"
import ProjectListItem from "../components/project-list-item"
import ProjectListItemEmpty from "../components/project-list-item-empty"
import Map from "../components/map"
import Marker from "../components/marker"
import { Box, Grid, Heading } from "theme-ui"
import SEO from "../components/seo"
// import PropTypes from "prop-types"

/*
 * Esta es la forma abreviada de declarar un componente
 * Fijate que usa {} por que tiene variables dentro
 * Luego hacer un return.
 * Mira comentarios en archivo layout
 *
 */
const TagsMaps = ({ pageContext, data }) => {

  // todos los items map en un array
  const allItemsAsArray = data.allWordpressWpSmbpMap.edges

  // los dos estados.
  // declarado el primer estado. Carga todos los items map.
  const [allItems, setHidden] = useState(allItemsAsArray)

  const [isHover, setIsHover] = useState(false)

  return (
    <Layout>
      <SEO title={data.site.siteMetadata.title} description="Mapa de projectos. Convocatoria por ámbitos" />
      <Header>
        <Heading as={`h1`}>{pageContext.name}</Heading>
        <FilterCatsDropdown
          allItemsAsArrayFromMainComponent={allItemsAsArray}
          setHiddenFromMainComponent={setHidden}
        />
        <FilterCatsRadio
          allItemsAsArrayFromMainComponent={allItemsAsArray}
          setHiddenFromMainComponent={setHidden}
        />
      </Header>
      <Main>
        <Grid
          as={`div`}
          gap={0}
          columns={[`1fr`,`1fr`, `1fr`, `22rem 1fr`,`24rem 1fr`]}
          sx={{
            gridTemplateAreas:[`"map" "items"`, `"map" "items"`, `"map" "items"`, `"items map"`, `"items map"`],
            ".items-container": {
              backgroundColor: `#d4d4d4`,
              gridArea: `items`,
              maxHeight: [`none`, `none`, `none`, `100vh`, `100vh`],
              overflow: [`auto`, `auto`, `auto`, `scroll`, `scroll`],
            },
            ".map-container": {
              gridArea: `map`,
            }
          }}
        >
          <Box
            as={`div`}
            className="items-container"
          >
            {allItems.length > 0
              ? allItems.map(({ node, index }) => (
                <ProjectListItem
                  key={node.id}
                  value={node.id}
                  classesranking={node.ranking}
                  librarytitlelist={node.title}
                  libraryweb={node.acf.fb_web}
                  librarymail={node.acf.fb_mail}
                  librarylocation={node.acf.fb_address}
                  libraryprovince={node.acf.fb_address_2}
                  libraryccaa={node.acf.fb_ccaa}
                  linktoproject={node.slug}
                  projectname={node.acf.fb_project_name}
                  presentationyear={node.acf.fb_presentation_year}
                  setIsHoverFromMainComponent={setIsHover}
                />
              ))
              : <ProjectListItemEmpty>
                  <p>No se va presentar ningun proyecto en este ámbito.</p>
                </ProjectListItemEmpty>
            }
          </Box>
          <Box
            as={`div`}
            className="map-container"
          >
            <Map>
              {allItems.length > 0 &&
                allItems.map(({ node, index }) => (
                  <Marker
                    key={node.id}
                    lat={node.acf.fb_address_map_final.lat}
                    lng={node.acf.fb_address_map_final.lng}
                    classesranking={node.ranking}
                    librarytitlemap={node.title}
                    isHoverLorem={isHover}
                  />
                ))
              }
            </Map>
          </Box>
        </Grid>
      </Main>
    </Layout>
  )
}

//
// CatsMaps.propTypes = {
//   pageContext: PropTypes.shape({
//     cat: PropTypes.string.isRequired,
//   }),
//   data: PropTypes.shape({
//     allWordpressWpSmbpMap: PropTypes.shape({
//       totalCount: PropTypes.number.isRequired,
//       edges: PropTypes.arrayOf(
//         PropTypes.shape({
//           node: PropTypes.shape({
//             title: PropTypes.string.isRequired,
//             id: PropTypes.string.isRequired,
//             slug: PropTypes.string.isRequired,
//             acf: PropTypes.shape({
//               fb_activities: PropTypes.string.isRequired,
//             }),
//           }),
//         }).isRequired
//       ),
//     }),
//   }),
// }

export default TagsMaps

export const pageQuery = graphql`
  query($wpid: Int) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allWordpressWpSmbpMap( filter: { tags_map: {elemMatch: {wordpress_id:{ in: [$wpid] } } } } ) {
      edges {
        node {
          id
          title
          slug
          ranking
          categories_map {
            wordpress_id
            name
          }
          acf {
            fb_address
            fb_address_2
            fb_address_map_final {
              lat
              lng
            }
            fb_ccaa
            fb_mail
            fb_project_name
            fb_web
            fb_presentation_year
          }
        }
      }
    }
  }
`
